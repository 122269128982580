<template>
  <div class="list-info">
    <top-bar title="走访列表" :left="true"></top-bar>
    <div class="search">
      <input v-model="searchValue" type="search" value="搜索" placeholder="请输入走访人姓名" class="search-content" @keyup.enter="onSearch">
      <img :src="require('@/assets/img/clear.png')" class="search-clear" @click="onCancel">
      <div class="search-btn" @click="onSearch">搜索</div>
    </div>
    <div class="filterBar">
      <van-row>
        <van-col span="8">
          <p @click="gridShow = !gridShow" :style="{color:gridShow?'#387FF5':'#666666'}">
            {{gridName || '所属网格'}}
            <img :src="require(`@/assets/img/${gridShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
        <van-col span="8">
          <p @click="dateBarShow = !dateBarShow" :style="{color:dateBarShow?'#387FF5':'#666666'}">走访时间</p>
        </van-col>
        <van-col span="8">
          <p @click="userShow = !userShow" :style="{color:userShow?'#387FF5':'#666666'}">
            {{userName}}
            <img :src="require(`@/assets/img/${userShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
      </van-row>
    </div>
    <van-popup v-model="gridShow" position="bottom"  >
      <van-picker title="请选择" show-toolbar :columns="gridList" value-key="label" @confirm="gridConfirm" @cancel="gridCancel"  />
    </van-popup>
    <div class="dateBar" v-show="dateBarShow">
      <van-row>
        <van-col span="8" @click="beginDateShow = !beginDateShow"><span>{{selectDate.beginDate == ''?'最早':selectDate.beginDate}}</span></van-col>
        <van-col span="2" ><span>-</span></van-col>
        <van-col span="8" @click="endDateShow = !endDateShow"><span>{{selectDate.endDate== ''?'至今':selectDate.endDate}}</span></van-col>
        <van-col span="3" @click="dateClose"><span>重置</span></van-col>
        <van-col span="3" @click="changeDate"><span>确定</span></van-col>
      </van-row>
    </div>
    <van-popup v-model="beginDateShow" position="bottom"  >
      <van-datetime-picker @confirm="beginDateConfim" @cancel="beginDateShow = false" :formatter="formatDate"
                           type="date" :min-date="new Date(2017, 0, 1)" :max-date="new Date()"/>
    </van-popup>
    <van-popup v-model="endDateShow" position="bottom"  >
      <van-datetime-picker @confirm="endDateConfim" @cancel="endDateShow = false" :formatter="formatDate"
                           type="date" :min-date="new Date(2017, 0, 1)" :max-date="new Date(2025, 10, 1)"/>
    </van-popup>
    <van-popup v-model="userShow" position="bottom"  >
      <van-picker title="请选择" show-toolbar :columns="userList" value-key="label" @confirm="userConfirm" @cancel="userShow = false"  />
    </van-popup>
    <div class="add-box" @click="goAdd">
      <div class="add-img">
        <img class="add-icon" :src="require('@/assets/img/add.png')" alt="">
        <div class="text">新增、添加</div>
      </div>
    </div>
    <div class="cont">
      <!-- <van-pull-refresh v-model="pullLoading" success-text="刷新成功" @refresh="onPullDownRefresh"> -->
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="10">
          <div class="totalCount">共有&nbsp;<span style="color:#387FF5">{{totalCount}}</span>&nbsp;条数据</div>
          <div class="list-item" v-for="item in dataList" :key="item.id" @click="getInfo(item.id, item.visitBody, item.fullLocation)">
            <div class="title">
              <div class="title-left">
                {{item.createUserName + ' | ' + item.createTime}}
              </div>
              <div class="title-right" :style="{color:item.status==10?'#FE5E5E':'#3E7EFE'}">
                {{item.serviceTypeStr}}
              </div>
            </div>
            <div class="content">
              <van-image :src="item.url?item.url:require('@/assets/img/flower.png')" class="content-img">
                <template slot="error">
                  <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                </template>
                <template slot="loading">
                  <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                </template>
              </van-image>
              <div class="content-text">
                <div class="top-text">{{item.visitBody == 2 ? item.placeStr : item.fullLocation == null ? '无房户' : item.fullLocation}}</div>
                <div class="bottom-text">{{item.visitDes}}</div>
              </div>
              <van-icon name="arrow" color="#666" class="content-icon"/>
            </div>
            <div style="height:40px">
              <div class="reAdd" @click.stop="goAdd(item)">再走一次</div>
            </div>

          </div>
        </van-list>
        <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      <!-- </van-pull-refresh> -->
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {formatterDate} from '@/utils/utils'
import {getImageStream} from '@/utils/index'
import {mapMutations, mapState} from 'vuex'
export default {
  name: 'visit',
  components :{
    topBar
  },
  data() {
    return {
      gridName: '所属网格',
      userName: '所属社工',
      loading: false,
      pullLoading: false,
      finished: false,
      endDateShow: false,
      beginDateShow: false,
      dateBarShow: false,
      gridShow: false,
      userShow: false,
      page: 0,
      limit: 10,
      searchValue: '',
      selectDate: {
        endDate: '',
        beginDate: ''
      },
      gridList: [],
      userList: [],
      totalCount: 0,
      dataList: [],
      houseId: null
    };
  },
  methods: {
    ...mapMutations,
    imgUrl (img) {
      if (img.length!==0) {
        return getImageStream(img[0].filePath)
      } else {
        return require('@/assets/img/flower.png')
      }
    },
    getInfo (id, visitType, location) {
      let type = ''
      if (visitType == 2) {
        type = '3'
      } else {
        type = location ? '1' : '2'
      }
      this.$router.push({path: '/visit-add', query: {id: id, type: type}})
    },
    getDataList () {
      this.page++
      this.$toast.loading({duration: 0,message: '加载中...',forbidClick: true,});
      this.$httpApp({
        url: this.$httpApp.adornUrl('/wxapp/visit/list'),
        method: 'post',
        params: this.$httpApp.adornParams({
          orgId: this.$orgId,
          page: this.page,
          limit: this.limit,
          name: this.searchValue,
          startDates: this.selectDate.beginDate,
          endDates: this.selectDate.endDate,
          gridId: this.gridId,
          workerType: this.userId,
          idNumber: '',
          username: '',
          labelCondition: 1,
          labels: [],
          houseId: this.houseId
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.pullLoading = false
          this.totalCount = data.page.totalCount
          if (this.dataList.length == data.page.totalCount) {
            this.finished = true
          }
          this.dataList = this.dataList.concat(data.page.list)
          // 加载状态结束
          this.loading = false;
          this.$toast.clear()
        }
        this.$toast.clear()
      },err=> {this.$toast.clear()})
    },
    getGridList () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/grid/info/listByUser'),
        method: 'post',
        params: this.$http.adornParams({
          community: this.$orgId
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.gridList = data.grids
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    getUserList () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/orgUserManagerTree'),
        method: 'get',
        params: this.$http.adornParams({
          orgId: this.$orgId
        },false)
      }).then(({data})=> {
        if (data.code == 0) {
          data.userManagerTree.map(item => {
            if (item.label === '社工') {
              this.userList = item.children
            }
          })
          // 默认显示当前社工名字
          let that = this
          this.userList.forEach(function(item) {
            if (item.value == that.$globalData.userInfo.userId) {
              that.userName = item.label
              return
            }
          });
          this.userList.unshift({
            label: '所有',
            value: 0
          })
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    formatDate (type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      return val
    },
    onPullDownRefresh(e) {
      this.finished=false
      this.dataList = []
      this.page = 0;
      this.getDataList()
    },
    onSearch(val) {
      this.page = 0
      this.dataList = []
      this.finished = false
      this.getDataList()
    },
    onCancel() {
      this.page = 0
      this.dataList = []
      this.finished = false
      this.searchValue = ''
      this.getDataList()
    },
    beginDateConfim (value) {
      let d = new Date(this.selectDate.endDate)
      if (this.selectDate.endDate != '' && d.getTime(d) < value.getTime(value)) {
        return this.$toast.fail('开始时间不能比结束时间大哦')
      }
      this.selectDate.beginDate = formatterDate(value)
      this.beginDateShow = false
    },
    endDateConfim (value) {
      let d = new Date(this.selectDate.beginDate)
      if (this.selectDate.beginDate != '' && d.getTime(d) > value.getTime(value)) {
        return this.$toast.fail('结束时间不能比开始时间小哦')
      }
      this.selectDate.endDate = formatterDate(value)
      this.endDateShow = false
    },
    changeDate () {
      this.dataList = []
      this.page = 0
      this.finished = false
      this.getDataList()
    },
    dateClose () {
      this.selectDate = {
        endDate: '',
        beginDate: ''
      }
    },
    gridConfirm (value,index) {
      this.page = 0
      this.dataList = []
      this.gridName = value.label
      this.gridId = value.value
      this.finished = false
      this.getDataList()
      this.gridShow = false
    },
    gridCancel() {
      this.page = 0
      this.dataList = []
      this.gridId = ''
      this.gridName = '所属网格'
      this.gridShow = false
      this.getDataList()
    },
    userConfirm (value,index) {
      this.page = 0
      this.dataList = []
      this.finished = false
      this.userName = value.label
      this.userId = value.value
      this.getDataList()
      this.userShow = false
    },
    goAdd (query) {
      if (query.visitBody) {
        query.id = ''
        query.reAdd = true
        this.$router.push({path: '/visit-add', query: query, meta:{keepAlive: true}})
      } else {
        this.$router.push({path: '/visit-add', query: this.$route.query, meta:{keepAlive: true}})
      }
    }
  },
  mounted () {
    if (this.$route.query.houseId) {
      this.houseId = this.$route.query.houseId
    }
    this.$toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    });
    this.userId = this.$globalData.userInfo.userId
    this.getDataList()
    this.getGridList()
    this.getUserList()
  }
}
</script>
<style scoped>
.reAdd {
  float: right;
  width: 160px;
  line-height: 60px;
  text-align: center;
  background-color: rgb(234,242,253);
  color: #387FF5;
  border-radius: 30px;
}
</style>
